FontAwesomeConfig = { searchPseudoElements: true };
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';
import "@lottiefiles/lottie-player";
// import LocomotiveScroll from 'locomotive-scroll';


jQuery(document).ready(function($) {
    var baseUrl = window.location.origin;

// /* ====================== SMOOTH SCROLL ======================  */
//     const scroller = new LocomotiveScroll({
//       el: document.querySelector('[data-scroll-container]'),
//       smooth: true
//     });

/* ====================== USER AGENT HEIGHT ======================  */
   if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && window.innerHeight > window.innerWidth) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', vh+'px');
      document.documentElement.style.setProperty('--staticvh', vh+"px");
      window.addEventListener('resize', function() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh+'px');
      });
  }

/* ====================== SCROLL FUNCTIONS ======================  */
    var lastScrollTop = 0;
    var headerHeight = $('.header').outerHeight();

    $(window).scroll(function(){
        var st = $(this).scrollTop();

        if(st > lastScrollTop && st > headerHeight) {
            $('.header').addClass('header--fixed');
            $("body").addClass("header-scrolling");
            if($('.header').hasClass('header--up')) {
                $(".header").removeClass("header--up");
            }
        }

        if(lastScrollTop >= st && st > headerHeight && !$('body').hasClass('page-template-services') && !$('body').hasClass('tax-collection')) {
            $(".header").addClass("header--up");
        }

        if(st <= 0) {
            $(".header").removeClass("header--up").removeClass("header--fixed");
            $("body").removeClass("header-scrolling");
        }

        lastScrollTop = st;
    });



/* ====================== Lets connect dialogue ======================  */
    const letsConnectOpen = $('.heading-content-row');
    const letsConnectDialog = document.getElementById('lets-connect');
    const letsConnectClose = document.getElementById('lets-connect-close');

    letsConnectOpen.on("click", function(e){
        if ($(e.target).is('a') || $(e.target).closest('a').length) {
            return;
        }

        letsConnectDialog.showModal();
    });

    window.addEventListener('click', (event) => {
      if (event.target === letsConnectDialog) {
          letsConnectDialog.close();
      }
    });

    letsConnectClose.addEventListener('click', () => {
      letsConnectDialog.close();
    });

/* ====================== SMOOTH SCROLL HASH LINK ======================  */
    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();
        headerHeight = $(".header").outerHeight() + 50;
        if($('body').hasClass('page-template-templates')) {
            var headerHeight = headerHeight - 150;
        } 

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top - headerHeight
        }, 500);
    });


/* ====================== VIDEO PLAYER ======================  */
  $('.showreel .showreel__volume').on('click', function(e) {
    $('.showreel__video').prop("muted", !$('.showreel__video').prop("muted"));
    if($(this).find('svg').hasClass('fa-volume-off')) {
      $(this).find('svg').removeClass('fa-volume-off').addClass('fa-volume-high');
    } else {
      $(this).find('svg').removeClass('fa-volume-high').addClass('fa-volume-off');
    }
  });


/* ====================== FOCE FILTER CATEGORY REDIRECT ======================  */
    $('.blog__filter .blog-category-selector').change(function () {
        location.href = '/category/' + $(this).val() + '/';
    });

/* CV INPUT CHANGE
======================================== */
  $("#input_3_6").on("change", function() {
    var filename = this.value;
    var parentGField = $(this).closest('.gfield');
    var gfieldLabel = parentGField.find('.gfield_label');
    filename = filename.split('\\');
    filename.reverse();
    gfieldLabel.html(filename[0]);
  })

 /* ====================== REMOVE EMPTY P TAG ======================  */
    $('p').each(function() {
        var $this = $(this);
        if ($this.html().replace(/\s|&nbsp;/g, '').length == 0)
            $this.remove();
    });

 /* ====================== FOOTER PUSHER ======================  */
  var referenceHeight = $('.footer').outerHeight();
  $('.footer-pusher').height(referenceHeight);

/* ====================== GRAVITY FORM NEXT PAGE MAKE A BUTTON ======================  */
    $('.gform_page').each(function() {
        var $nextLink = $(this).find('.gform_page_footer .gform_next_button');
        if ($nextLink.length) {
            var currentPageId = $(this).attr('id');
            $nextLink.replaceWith('<button type="button" class="ui-link gform_next_button" id="' + currentPageId + '"><span class="ui-link__circle"><img src="'+baseUrl+'/wp-content/themes/platform81/library/images/icons/icon-arrow.svg" class="ui-link__arrow"></span><span class="ui-link__text">Next</span></button>');
        }
    });

    // Handle click event for the "Next" button
    $(document).on('click', '.gform_next_button', function(e) {
        e.preventDefault();
        // Proceed to the next step
        var currentPageId = $(this).attr('id');
        var $currentPage = $('#' + currentPageId);
        var $nextPage = $currentPage.next('.gform_page');
        if ($nextPage.length) {
            $currentPage.hide();
            $nextPage.show();
        }
    });

    const container = document.querySelector('.p-before-after-image__block');
    document.querySelector('.slider').addEventListener('input', (e) => {
      container.style.setProperty('--position', `${e.target.value}%`);
    })
});
