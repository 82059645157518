jQuery(document).ready(function($) {
if($('body').hasClass('page-template-ppc') || $('body').hasClass('page-template-ppc-thank-you') || $('.google-reviews').length) {
  var $ppc_reviews = $('.google-reviews .wp-google-reviews').slick({
    speed: 800,
    cssEase: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    initialSlide: 0,
    arrows: false,
    buttons: false,
    touchThreshold: 1000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
        slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
        slidesToShow: 1,
        }
      },
    ]
  });

  var $progressBarSpan3 = $('.google-reviews__progress__bar');
  var $progressBarLabel3 = $( '.google-reviews .slider__label' );

  $ppc_reviews.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;

    $progressBarSpan3
      .css('width', calc + '% ')
      .attr('aria-valuenow', calc );

    $progressBarLabel3.text( calc + '% completed' );
  });

  var cursor = document.querySelector('.cursor-drag');
  var draggable = document.querySelectorAll('.is-draggable');

  document.addEventListener('mousemove', function(e){
    var x = e.clientX;
    var y = e.clientY;
    cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`
  });


  if(draggable.length) {
    draggable.forEach(item => {
      item.addEventListener('mouseover', () => {
        cursor.classList.add('cursor-drag--show');
      });
      item.addEventListener('mouseleave', () => {
        cursor.classList.remove('cursor-drag--show');
      });
    });
  }
}

if($('body').hasClass('page-template-ppc')) {
	var $ppc_logos = $('.ppc-logos__slider').slick({
		speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    centerMode: true,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: true,
    initialSlide: 0,
    arrows: false,
    buttons: false,
    draggable: false,
	});

	var $ppc_process = $('.ppc-process__slider').slick({
    autoplay: false,
    speed: 1000,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    touchThreshold: 1000,
    //arrows: false,
    buttons: false,
    infinite: true,
    cssEase: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
    prevArrow: $('.pcc-prev-button'),
    nextArrow: $('.pcc-next-button'),

	});

	var $ppc_deliverables = $('.ppc-deliverables__slider').slick({
    speed: 800,
    cssEase: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    initialSlide: 0,
    arrows: false,
    buttons: false,
    touchThreshold: 1000,
    responsive: [
			{
			  breakpoint: 1200,
			  settings: {
				slidesToShow: 2,
			  }
			},
			{
			  breakpoint: 768,
			  settings: {
				slidesToShow: 1,
			  }
			},
		]
	});

	var $progressBarSpan = $('.ppc-deliverables__progress__bar');
  var $progressBarLabel = $( '.ppc-deliverables .slider__label' );
  
  $ppc_deliverables.on('beforeChange', function(event, slick, currentSlide, nextSlide) {   
    var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;
    
    $progressBarSpan
      .css('width', calc + '%	')
      .attr('aria-valuenow', calc );
    
    $progressBarLabel.text( calc + '% completed' );
  });
  
  var $ppc_work = $('.ppc-work__slider').slick({
    speed: 800,
    cssEase: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    initialSlide:0,
    arrows: false,
    buttons: false,
    touchThreshold: 1000,
    responsive: [
			{
			  breakpoint: 1200,
			  settings: {
				slidesToShow: 2,
			  }
			},
			{
			  breakpoint: 768,
			  settings: {
				slidesToShow: 1,
			  }
			},
		]
	});

	var $progressBarSpan2 = $('.ppc-work__progress__bar');
  var $progressBarLabel2 = $( '.ppc-work .slider__label' );
  
  $ppc_work.on('beforeChange', function(event, slick, currentSlide, nextSlide) {   
    var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;
    
    $progressBarSpan2
      .css('width', calc + '%	')
      .attr('aria-valuenow', calc );
    
    $progressBarLabel2.text( calc + '% completed' );
  });
  
	// Handle tab click event
	$('.ppc-process__tabs a').click(function() {
	    var slideIndex = $(this).data('slide-index');
	    $ppc_process.slick('slickGoTo', slideIndex);
	});

	// Update active tab when slider changes
	$ppc_process.on('afterChange', function(event, slick, currentSlide) {
	    $('.ppc-process__tabs a').removeClass('active');
	    $('.ppc-process__tabs a[data-slide-index="' + currentSlide + '"]').addClass('active');
	});


  /* ====================== Lets connect dialogue ======================  */
  const letsConnectOpen = $('.ppc-form-toogle');
  const letsConnectDialog = document.getElementById('lets-connect');
  const letsConnectClose = document.getElementById('lets-connect-close');

  letsConnectOpen.on("click", function(e){
  letsConnectDialog.showModal();
  })

  //close dialog on click outside
  window.addEventListener('click', (event) => {
    if (event.target === letsConnectDialog) {
        letsConnectDialog.close();
    }
  });

  //close dialog on click of close button
  letsConnectClose.addEventListener('click', () => {
    letsConnectDialog.close();
  });

}
});
